import request from "@/utils/request"
//查询项目考察列表
export function demandlist(query) {
    return request({
        url: "/demand/list",
        method: "get",
        params:query
    })
}
//考察需求信息
export function getdemand(id) {
    return request({
        url: "/demand/"+id,
        method: "get",

    })
}
//新增项目
export function adddemand(data) {
    return request({
        url: "/demand",
        method: "post",
        data:data
    })
}
//修改项目
export function editdemand(data) {
    return request({
        url: "/demand",
        method: "put",
        data:data
    })
}
//配置行程一览
export function managementlist(query) {
    return request({
        url: "/management/list",
        method: "get",
        params:query
    })
}
//新增行程
export function addmanagement(data) {
    return request({
        url: "/management",
        method: "post",
        data:data
    })
}
//编辑
export function editmanagement(data) {
    return request({
        url: "/management",
        method: "put",
        data:data
    })
}

//行程类型code
export function getCode(query) {
    return request({
        url: "/code/getCode",
        method: "get",
        params:query
    })
}
//获取行程管理详细
export function getmanagement(id) {
    return request({
        url: "/management/"+id,
        method: "get",

    })
}
//删除行程
export function delmanagement(query) {
    return request({
        url: "/management/",
        method: "delete",
        params:query

    })
}
//新增行程记录信息
export function addTripRecordert(data) {
    return request({
        url: "/management/addTripRecorder",
        method: "post",
        data:data
    })
}
//修改行程信息
export function updateTripRecorder(data) {
    return request({
        url: "/management/updateTripRecorder",
        method: "post",
        data:data
    })
}
//获取行程记录信息
export function getTripRecorder(id) {
    return request({
        url: "/management/getTripRecorder/"+id,
        method: "get",

    })
}
//配置企业列表
export function Companylist(query) {
    return request({
        url: "/visitingCompany/list",
        method: "get",
        params:query

    })
}
//新增行程附件
export function addtripAccessory(data) {
    return request({
        url: "/accessory",
        method: "post",
        data:data
    })
}
//查询附件一览
export function accessorylist(query) {
    return request({
        url: "/accessory/listt",
        method: "get",
        params:query

    })
}
//获取省

export function getProvince() {
    return request({
        url: "/city/getProvince",
        method: "get",
    })
}
//城市
export function getByFatherIdt(query) {
    return request({
        url: "/city/getAllCity",
        method: "get",
        params:query

    })
}
//获取区
export function getAllArea(query) {
    return request({
        url: "/city/getAllArea",
        method: "get",
        params:query

    })
}

//查秘书长会员用户
export function getMemberAll() {
    return request({
        url: "/wechatUser/getMemberAll",
        method: "get",

    })
}
//修改项目状态
export function editType(data) {
    return request({
        url: "/demand/editType",
        method: "put",
        data:data
    })
}
//根据名称查询企业一览
export function getCompanyList(query) {
    return request({
        url: "/company/getCompanyList",
        method: "get",
        params:query

    })
}
//新增参访企业
export function addvisiting(data) {
    return request({
        url: "/visitingCompany",
        method: "post",
        data:data
    })
}
//修改参访企业
export function editvisiting(data) {
    return request({
        url: "/visitingCompany",
        method: "put",
        data:data
    })
}
//删除参访企业
export function delvisiting(query) {
    return request({
        url: "/visitingCompany",
        method: "delete",
        params:query
    })
}
//查询参与嘉宾列表
export function getparticipantsList(query) {
    return request({
        url: "/participants/list",
        method: "get",
        params:query

    })
}
//新增嘉宾
export function addparticipants(data) {
    return request({
        url: "/participants",
        method: "post",
        data:data
    })
}
//删除嘉宾
export function delparticipants(query) {
    return request({
        url: "/participants",
        method: "delete",
        params:query
    })
}
//查询嘉宾详细信息
export function getparticipants(id) {
    return request({
        url: "/participants/"+id,
        method: "get",

    })
}
//修改参与嘉宾
export function editparticipants(data) {
    return request({
        url: "/participants",
        method: "put",
        data:data
    })
}
//查询联系我
export function getcontactUs(query) {
    return request({
        url: "/contactUs/list",
        method: "get",
        params:query

    })
}

//点击联系
export function contactUs(data) {
  return request({
      url: "/contactUs",
      method: "put",
      data
  })
}

//考察领域
export function getFieldList(query) {
    return request({
        url: "/code/getFieldList",
        method: "post",
        params:query

    })
}
//获取异地考察结果详细信息
export function gwtresult(inspectDemandId) {
    return request({
        url: "/result/"+inspectDemandId,
        method: "get",

    })
}
//新增异地考察结果
export function addresult(data) {
    return request({
        url: "/result",
        method: "post",
        data:data
    })
}
//编辑异地异地考察结果
export function editresult(data) {
    return request({
        url: "/result",
        method: "put",
        data:data
    })
}
//发布行程
export function releaseTrip(query) {
    return request({
        url: "/management/releaseTrip",
        method: "put",
        params:query
    })
}
//发布报告
export function getreleaseResult(query) {
    return request({
        url: "/result/releaseInspectDemandResult",
        method: "put",
        params:query
    })
}
//匹配考察需求相关的企业
export function getmatching(query) {
    return request({
        url: "/demand/matchingEnterprise",
        method: "post",
        params:query
    })
}
//加入发布已选企业
export function updateSelectedStatus(data) {
    return request({
        url: "/visitingCompany/updateSelectedStatus",
        method: "put",
        data:data
    })
}
//设置关联度
export function updateSelected(data) {
    return request({
        url: "/visitingCompany/update",
        method: "put",
        data:data
    })
}
//移除
export function delvisiti(ids) {
    return request({
        url: "/visitingCompany/delete/"+ids,
        method: "delete",

    })
}
//删除考察项目
export function deldemand(ids) {
    return request({
        url: "/demand/"+ids,
        method: "delete",

    })
}
//回去考察需求头部信息
export function getdemandtop(query) {
    return request({
        url: "/demand/top",
        method: "get",
        params:query
    })
}
//配置报告新增参访企业
export function getVisitingCompany(query) {
    return request({
        url: "/visitingCompany/getVisitingCompany",
        method: "get",
        params:query
    })
}
//标签新增
export function getCodeMasterByParenId(query) {
    return request({
        url: "/code/getCodeMasterByParenId",
        method: "post",
        params:query
    })
}
//根据考察项目ID查询用户所提交的企业名称（新）
export function getWechatUserCompanyName(query) {
    return request({
        url: "/wechatUserCompany/getWechatUserCompanyName",
        method: "get",
        params:query
    })
}
//根据考察项目ID查询用户所提交的企业名称（旧，不包含新）
export function getWechatUserCompanyNameOld(query) {
    return request({
        url: "/wechatUserCompany/getWechatUserCompanyNameOld",
        method: "get",
        params:query
    })
}
//修改行程管理启用未启用
export function editAvailable(data) {
    return request({
        url: "/management/editAvailable",
        method: "put",
        data:data
    })
}














