<template>
  <div class="travel">
    <div class="head_img">
      <div class="img_title">
        <div class="img">
          <img v-if="demandinfo.projectImg" :src="demandinfo.projectImg" alt="" />
          <img v-else src="../../assets/image/Group 3163.png" alt="" />
        </div>
        <div class="title">
          <p class="merchant_name">{{ demandinfo.projectName }}</p>
          <div class="storeCenter_item_top_left_flex_tag">
            <span v-for="(o, index) in demandinfo.areasInvestigation" :key="index">
              <span v-if="index < 2" class="office">{{ o.areas[1] }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="usercompanyinfo != null || usercompanyinfolod != null"
      style="background: #ffffff; margin-top: 30px; padding: 20px 20px"
    >
      <h4
        v-if="usercompanyinfo != null && usercompanyinfolod != null"
        style="color: #4a8cff; font-size: 20px"
      >
        客户新增企业({{
          usercompanyinfo.companyName.split(',').length +
          usercompanyinfolod.companyName.split(',').length
        }})
      </h4>
      <h4 v-else style="color: #4a8cff; font-size: 20px">
        客户新增企业({{ usercompanyinfo.companyName.split(',').length }})
      </h4>
      <div class="input-with">
        <div style="display: flex">
          <div class="compony_flex_left" v-if="usercompanyinfo">
            <div class="company_newest">
              <div class="company_newest_red">
                <span>最新</span>
              </div>
              <div class="company_name">
                <span>{{ usercompanyinfo.companyName }}</span>
              </div>
            </div>
            <div class="company_newest_bottom">
              <span class="time_name">新增时间：</span>
              <span>{{ usercompanyinfo.createTime }}</span>
            </div>
          </div>
          <div class="compony_flex_left" v-if="usercompanyinfolod">
            <div class="company_newest">
              <div style="height: 19px"></div>
              <div class="company_name">
                <span>{{ usercompanyinfolod.companyName }}</span>
              </div>
            </div>
            <div class="company_newest_bottom">
              <span class="time_name">新增时间：</span>
              <span>{{ usercompanyinfolod.createTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="background: #ffffff; margin-top: 30px; padding: 20px 20px">
      <h4 style="margin: 0px 0px 30px 0px">参访企业({{ total }})</h4>
      <div class="input-with">
        <div style="display: flex">
          <el-button type="primary" plain icon="el-icon-plus" @click="handleAdd">新增</el-button>
          <!-- <el-button >批量导出</el-button> -->
          <div class="top_info" style="margin-left: 20px">
            <span>
              <img src="../../assets/image/Volume Up.png" alt="" />
            </span>
            <div class="top_time">
              <div>
                <span style="color: #75757d">最新更新时间：</span>
                <span>{{ demandinfo.updateTime }}</span>
              </div>

              <div>
                <span style="color: #75757d">更新人：</span>
                <span>{{ demandinfo.updateName }}</span>
              </div>
            </div>
          </div>
        </div>
        <el-input
          placeholder="请输入企业名称"
          v-model="queryInfo.companyName"
          class="input-with-select"
        >
          <el-button class="seach" slot="append" @click="search()">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="tab_txt" v-loading="loading">
      <div class="storeCenter_item" v-for="(item, index) in storeList" :key="index">
        <div class="item_checkbox">
          <el-checkbox v-model="item.checked1" label=""></el-checkbox>
        </div>
        <div class="storeCenter_item_top">
          <div class="storeCenter_item_top_img" v-if="item.isSuitable == 1">
            <img src="../../assets/image/不合适.png" alt="" />
          </div>
          <div class="storeCenter_item_top_left">
            <img v-if="item.companyLogo" :src="item.companyLogo" alt="" />
            <img v-else src="../../assets/image/Group 3237.png" alt="">
            <div class="storeCenter_item_top_left_flex">
              <div style="display: flex; align-items: center">
                <h4 style="font-size: 18px">{{ item.companyFullName }}</h4>
              </div>
              <div class="storeCenter_item_top_left_flex_tag">
                <span v-for="(o, index) in item.labelName" :key="index">
                  <span v-if="index < 3 && o != ''" class="office">{{ o }}</span>
                </span>
              </div>
              <div>
                <span style="color: #9b9a9a">关联秘书长:</span>
                <span style="padding: 0px 15px">{{ item.realName }}</span>
                <span style="color: #9b9a9a">秘书长联系电话:</span>
                <span style="padding: 0px 15px">{{ item.phone }}</span>
              </div>
            </div>
          </div>
          <div class="storeCenter_item_top_right">
            <div class="select" style="display: inline-block; padding-right: 10px">
              <el-select
                v-model="item.dockingStatus"
                placeholder="请选择"
                @change="changeStatus(item)"
              >
                <el-option
                  v-for="item in operatingList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <el-button class="buttom_right" @click="relevancy(item)">编辑</el-button>
            <span
              v-if="item.isSuitable == 0 || item.isSuitable == null"
              class="edit"
              @click="delrelevancy(item)"
              >冻结</span
            >
            <span v-if="item.isSuitable == 1" class="edit" @click="delrelevancy(item)">激活</span>
          </div>
        </div>
      </div>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog title="新增参访企业" :visible.sync="open" width="500px" append-to-body>
     <div class="form_input">
      <el-form ref="form" :model="form" label-width="90px">
        <el-form-item label="添加企业:">
          <!-- <el-select
            v-model="form.companyName"
            multiple
            filterable
            default-first-option
            placeholder="请输入您要添加的企业名称"
            @input="companychange"
          >
            <el-option
              v-for="(item, index) in buildlist"
              :key="index"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
          <el-autocomplete
            v-model="form.companyName"
            :fetch-suggestions="querySearchAsyncapt"
            placeholder="请输入您要添加的企业名称"
            @select="handleSelectcapt"
          >
            <template slot-scope="{ item }">
              <div class="name">
                <img
                  v-if="item.companyLogo"
                  :src="item.companyLogo"
                  style="border-radius: 28px"
                  width="28px"
                  height="28px"
                  alt=""
                />
                <img
                  v-else
                  src="../../assets/image/Group 3237.png"
                  style="border-radius: 28px"
                  width="28px"
                  height="28px"
                  alt=""
                />

                <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                  item.companyFullName
                }}</span>
              </div>
            </template>
          </el-autocomplete>
        </el-form-item>
      </el-form>
     </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getdemand,
  getdemandtop,
  Companylist,
  getCompanyList,
  delvisiting,
  addvisiting,
  editvisiting,
  getWechatUserCompanyName,
  getWechatUserCompanyNameOld
} from '@/api/demand'
export default {
  name: 'visitingCompany',
  computed: {
    inspectDemandId() {
      return this.$route.query.inspectDemandId * 1
    }
  },
  data() {
    return {
      demandinfo: {}, //头部信息
      storeList: [], //参访企业列表
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        companyName: null,
        inspectDemandId: this.$route.query.inspectDemandId,
        status: '1'
      }, //请求参访企业
      // 总条数
      total: 0, //条数
      open: false, //打开新增企业
      loading: false,
      form: {},
      companyName: [],
      companylist: [],
      buildlist: [], ////新增企业下拉框
      operatingList: [
        {
          id: '1',
          name: '对接中'
        },
        {
          id: '2',
          name: '已确认'
        },
        {
          id: '3',
          name: '已拒绝'
        }
      ],
      //客户新增
      usercompanyinfo: null,
      //客户之前新增
      usercompanyinfolod: null
    }
  },
  created() {
    this.search()
    this.getdemandtop()
    this.getWechatUserCompanyName()
    this.getWechatUserCompanyNameOld()
  },
  methods: {
    //最近更新时间
    async getdemandtop() {
      const res = await getdemandtop({ inspectDemandId: this.inspectDemandId })
      if (res.data.resultCode == 200) {
        this.demandinfo = res.data.data
        this.demandinfo.areasInvestigation = JSON.parse(this.demandinfo.areasInvestigation)
      }
    },
    //客户新增
    async getWechatUserCompanyName() {
      const res = await getWechatUserCompanyName({ inspectDemandId: this.inspectDemandId })
      if (res.data.resultCode == 200) {
        this.usercompanyinfo = res.data.data
      }
    },
    //客户之前新增
    async getWechatUserCompanyNameOld() {
      const res = await getWechatUserCompanyNameOld({ inspectDemandId: this.inspectDemandId })
      if (res.data.resultCode == 200) {
        this.usercompanyinfolod = res.data.data
      }
    },
    //参访企业一览
    async search() {
      this.loading = true
      const res = await Companylist(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.storeList = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
        this.storeList.forEach((item) => {
          if (item.labelName != null) {
            item.labelName = item.labelName.split(',')
          }
        })
      }
    },

    //获取可以新增的参访企业
    async getcompany() {
      if (this.form.companyName) {
        const res = await getCompanyList({
          companyName: this.form.companyName,
          inspectDemandId: this.inspectDemandId
        })
        if (res.data.resultCode == 200) {
          this.buildlist = res.data.data
          clearTimeout(this.timeout)
          console.log(this.buildlist)
        }
      }
    },

    //新增参访企业
    handleAdd() {
      this.open = true
      this.companyName = []
      this.companylist = []
      this.form={}
    },
    cancel() {
      this.open = false
      this.companyName = []
      this.companylist = []
      this.form={}
    },
    async querySearchAsyncapt(queryString, cb) {
      if (this.form.companyName) {
        const res = await getCompanyList({
          companyName: this.form.companyName,
          inspectDemandId: this.inspectDemandId
        })
        if (res.data.resultCode == 200) {
          this.buildlist = res.data.data
          cb(this.buildlist)
          console.log(this.buildlist)
        }
      } else {
        cb(this.buildlist)
      }
    },
    handleSelectcapt(item) {
     
      this.form.companyName=item.companyName
      this.companylist=[
      {
        companyId: item.id,
        companyName: item.companyName,
        dockingStatus: '1',
        id: null,
        inspectDemandId: this.inspectDemandId,
        isDelete: '0',
        isView: '0',
        selectedStatus: '1',
        source: '1'
      }
      ]
    },
    async submitForm() {
    
      const res = await addvisiting(this.companylist)
      if (res.data.resultCode == 200) {
        this.open = false
        this.$message.success('新增成功')
        this.companyName = []
        this.companylist = []
        this.form={}
        this.search()
      } else {
        this.form={}
        this.companylist = []
      }
    },

    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    //删除参访企业
    delrelevancy(row) {
      delete row.labelName
      this.$alert('您是否确认操作？')
        .then(function () {
          return delvisiting(row)
        })
        .then(() => {
          this.search()
          this.$message.success('操作成功')
        })
        .catch(() => {})
    },
    //编辑
    async changeStatus(item) {
      const res = await editvisiting(item)
      if (res.data.resultCode == 200) {
        this.$message.success('修改成功')

        this.search()
      }
    },

    relevancy(item) {
      this.$router.push({
        name: 'addenterprise',
        query: { id: item.companyId }
      })
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .form_input .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 300px;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #fff;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #fff;
  border-radius: 10px;
  background-color: #fff;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(71, 77, 72, 0.3);
  background-color: #fff;
}
::v-deep .select .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 100px;
}
::v-deep .el-step__icon.is-text {
  border-radius: 50%;
  border: 1px solid;
  color: #ffffff;
  border-color: #146aff;
  background: #146aff;
}
::v-deep .el-step__line {
  position: absolute;
  border-color: #ffffff;
  border: 1px dashed #b6d0ff;
  background-color: #ffffff;
}
::v-deep .el-step.is-vertical .el-step__line {
  width: 0px;
  top: 0;
  bottom: 0;
  left: 11px;
}
.travel {
  .head_img {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px 10px 15px 10px;
    background: #ffffff;
    .storeCenter_item_top_center {
      width: 500px;
      margin-left: 200px;
    }
    .img_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 10px 5px 10px;

      img {
        width: 68px;
        height: 68px;
        border-radius: 5px;
      }

      .title {
        margin: 0px 0px 0px 10px;
        display: flex;
        flex-direction: column;

        .merchant_name {
          font-weight: 550;
          font-size: 18px;
          color: #1f2d3d;
          margin: 0px;
          position: relative;
          top: -5px;
        }

        .storeCenter_item_top_left_flex_tag {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-content: center;
          padding-top: 13px;

          span {
            box-sizing: border-box;
            padding: 1px 6px;
            display: block;
            font-size: 14px;
            font-weight: 400;
            border-radius: 4px;
          }

          .office {
            position: relative;
            left: -3px;
            padding: 5px;
            color: #6090ee;
            background: #e1ecff;
          }

          .support {
            border: 1px solid #fd523f;
            color: #fd523f;
            margin-left: 12px;
          }
        }
      }
    }
  }
  h4 {
    color: #4a8cff;
    font-size: 20px;
    margin: 0px 0px 10px 0px;
  }
  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }
  .input-with {
    width: 100%;

    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .compony_flex_left {
      margin-right: 16px;
      .company_newest {
        width: 393px;
        height: 118px;
        background: #fafafa;
        .company_name {
          height: 68px;
          padding: 5px 15px;
          font-size: 18px;
          overflow-x: hidden;
          overflow-y: scroll;
        }
        .company_newest_red {
          font-size: 13px;
          color: #fff;
          width: 35px;
          height: 19px;
          background: #ff5151;
          border-radius: 2px;
          text-align: center;
          line-height: 19px;
        }
      }
      .company_newest_bottom {
        padding-top: 7px;
        .time_name {
          font-size: 12px;
          color: #75757d;
        }
        span {
          font-size: 12px;
        }
      }
    }
    .top_info {
      display: flex;
      align-items: center;
      img {
        width: 18px;
        height: 17px;
      }
      .top_time {
        display: flex;
        justify-content: space-around;
        font-size: 14px;
        width: 356.84px;
        height: 38px;
        background: #fafafa;
        border-radius: 8px;
        line-height: 38px;
      }
    }

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
  .tab_txt {
    margin-top: 20px;

    .storeCenter_item {
      width: 100%;
      overflow: hidden;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      box-sizing: border-box;
      position: relative;
      .item_checkbox {
        line-height: 36px;
        margin: 0px 10px;
      }
      .Relevancy {
        position: absolute;
        width: 93px;
        height: 34px;
        top: -0px;
        left: -0px;
        background: #fd6161;
        border-radius: 10px 15px 15px 0px;
        font-size: 13px;
        text-align: center;
        line-height: 34px;
        color: #ffffff;
      }

      .storeCenter_item_top {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        padding-bottom: 14px;
        position: relative;
        background: #ffffff;
        padding: 16px 20px 10px 10px;
        .storeCenter_item_top_img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          img {
            width: 120px;
            height: 100px;
          }
        }
        .storeCenter_item_top_left {
          display: flex;
          flex-direction: row;

          img {
            width: 98px;
            height: 98px;
            border-radius: 4px;
            margin-right: 14px;
          }

          .storeCenter_item_top_left_flex {
            flex: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h4 {
              color: #1f2d3d;
              font-size: 26px;
              font-weight: 500;
              margin: 0;
            }

            .storeCenter_item_top_left_flex_tag {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-content: center;

              span {
                box-sizing: border-box;
                padding: 1px 6px;
                display: block;
                font-size: 14px;
                font-weight: 400;
                border-radius: 4px;
              }

              .office {
                border: 1px solid #6090ee;
                color: #6090ee;
              }

              .support {
                border: 1px solid #fd523f;
                color: #fd523f;
                margin-left: 12px;
              }
            }
          }
        }
        .storeCenter_item_top_right {
          .buttom_right {
            height: 36px;
            line-height: 1px;
          }
          .edit {
            padding-left: 20px;
            color: #4e93fb;
            cursor: pointer;
          }
          .frozen {
            padding-left: 20px;
            color: #fd5469;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
